.dropDown {
    position: relative;
    width: 4rem;
    padding: 10px 20px;
}

.dropDown-button {
    all: unset;
    font-size: 1.2rem;
    border: none;
    background: none;
    position: relative;
    padding: 0;
    padding-right: 1.6rem;
    z-index: 5;
    color: var(--white-color);
    transition: 0.2s ease-in-out;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.02em;
    text-align: left;
}

.dropDown-button:hover {
    color: var(--hover-gray);
}

.dropDown-button::after {
    content: "";
    background-color: var(--white-color);
    position: absolute;
    height: 0.03rem;
    width: 0.6rem;
    top: 50%;
    right: 0%;
    transform: rotateZ(-45deg);
}

.dropDown-button::before {
    content: "";
    background-color: var(--white-color);
    position: absolute;
    height: 0.03rem;
    width: 0.6rem;
    top: 50%;
    right: 8%;
    transform: rotateZ(45deg);
}

.dropDown-button:hover {
    cursor: pointer;
}

.dropDown-hiddenContent {

    width: inherit;
    width: 100%;
    top: 0%;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: 0.2s ease-in-out;
}

.dropDown-showContent {
    max-height: 200px;
    top: 100%;
}

.dropDown-hiddenContent ul {
    margin: 0;
    padding: 0;
}

.dropDown-hiddenContent li {
    list-style: none;
    padding: 0;
    transition: 0.1s ease-in-out;
    color: var(--white-color);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.721rem;
    letter-spacing: -0.02em;
    text-align: left;
}

.dropDown-hiddenContent li:hover {
    cursor: pointer;
    color: var(--red-color)
}

@media (width < 1025px) {
    .dropDown-button {
        color: var(--white-color);
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .dropDown-button::after {
        background-color: var(--white-color);
    }

    .dropDown-button::before {
        background-color: var(--white-color);
    }

    .dropDown-hiddenContent li {
        color: var(--white-color);
    }
}