.homeGallerySectionContainer {
    position: relative;
    height: fit-content;
}

.homeGalleryBanner {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
}

.homeGalleryBanner img {
    width: 100%;
    aspect-ratio: 504 / 689;
    object-fit: cover;
    transition: 0.7s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}

.homeGalleryBanner-transition {
    position: relative;
}

.homeGalleryBanner-transition img {
    width: calc((100vw - 216px) / 3);
    top: calc(((100vw - 216px) / 3) * (689 / 504) + 28px + 100vh);
    left: calc((100vw - 216px) / 3 + 108px);
    transform: translateY(-50%);
}

.homeGalleryBanner-textContainer {
    color: var(--white-color);
    position: absolute;
    top: 167px;
    left: 189px;
    z-index: 3;
    opacity: 1;
    transition: 1s ease-in-out;
}

.homeGalleryBanner-textContainer-transition {
    opacity: 0;
}

.homeGalleryBanner-textContainer h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: -0.063rem;
    margin: 0;
    margin-bottom: 24px;
    width: 50%;
}

.homeGalleryBanner-textContainer p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin: 0;
    margin-bottom: 60px;
    width: 35%;
}

.homeGalleryBanner-textContainer span {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.7rem;
    border-bottom: 1px solid var(--white-color);
}

.homeGalleryContainer {
    height: calc(((100vw - 216px) / 3) * (689 / 504) * 2 + 56px);
    overflow: hidden;
    margin-right: 80px;
    margin-left: 80px;
}

.homeGalleryWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 28px;
}

.homeGallery-imgContainer-leftPosition {
    grid-column: 1;
    text-align: end;
}

.homeGallery-imgContainer-rightPosition {
    grid-column: 3;
    text-align: start;
}

.homeGallery-imgContainer-centerPosition {
    grid-column: 2;
    transform: translateY(-50%);
}

.homeGallery-imgContainer-centerPositionBG {
    width: 0;
    height: 0;
}

.homeGallery-imgContainer {
    width: 100%;
    aspect-ratio: 504 / 689;
}

.homeGallery-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (width < 1025px) {
    .homeGallerySectionContainer {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .homeGallerySectionContainer img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .homeGallerySectionContainer-infoContainer {
        position: absolute;
        left: 30px;
        bottom: 307px;
        color: var(--white-color);
        width: 530px;
    }

    .homeGallerySectionContainer-infoContainer h2 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.01em;
        margin: 0;
        margin-bottom: 16px;
    }

    .homeGallerySectionContainer-infoContainer p {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.406rem;
    }

    .homeGallerySectionContainer-linkContainer {
        position: absolute;
        right: 38px;
        bottom: 100px;
    }

    .homeGallerySectionContainer-linkContainer a {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.406rem;
        border-bottom: 1px solid var(--white-color);
        color: var(--white-color);
    }

}

@media (width < 800px) {
    .homeGallerySectionContainer-infoContainer {
        left: 0;
        bottom: 200px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 23px;
        padding-right: 17px;
    }

    .homeGallerySectionContainer-infoContainer h2 {
        line-height: 1.688rem;
        width: 319px;
    }

    .homeGallerySectionContainer-infoContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .homeGallerySectionContainer-linkContainer {
        right: 17px;
    }
}