.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--white-color);
    background-color: rgba(22, 22, 23, 0.85);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
}

.headerContainer-utilsContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 46px;
}

.header-mobileButton {
    display: none;
}

@media (width < 1025px) {
    .header-mobileButton {
        display: block;
        width: 27px;
        height: 7px;
        position: relative;
        margin: auto 0;
    }

    .header-mobileButton-line {
        width: 100%;
        height: 1px;
        background-color: var(--white-color);
        position: absolute;
        transition: 0.4s ease-in-out;
    }

    .header-mobileButton-topLine {
        top: 0;
    }

    .header-mobileButton-bottomLine {
        bottom: 0;
    }

    .header-mobileButton-active .header-mobileButton-topLine {
        transform: rotate(45deg) translateY(4px);
        transform-origin: center;
    }

    .header-mobileButton-active .header-mobileButton-bottomLine {
        transform: rotate(-45deg) translateY(-4px);
        transform-origin: center;
    }

    .headerContainer-utilsContainer {
        position: absolute;
        right: -100%;
        top: 100%;
        background-color: rgba(22, 22, 23, 0.85);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: var(--white-color);
        transition: 0.4s ease-in-out;
        width: 100%;
        gap: 0;
        padding-left: 30px;
        padding-right: 45px;
        padding-bottom: 20px;
        box-sizing: border-box;
        align-items: flex-start;
    }

    .headerContainer-utilsContainer-enter {
        right: 0;
    }
}