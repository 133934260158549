.homeOurServicesContainer {
    margin-top: 106px;
}

.homeOurServices-topInfoContainer {
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 180px;
}

.homeOurServices-topInfoContainer h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: -0.01em;
    width: 50%;
    margin: 0;
    margin-bottom: 24px;
}

.homeOurServices-topInfoContainer p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
    width: 40%;
    margin: 0;
}

@media (799px < width < 1025px) {
    .homeOurServicesContainer {
        margin-top: 60px;
    }

    .homeOurServices-topInfoContainer {
        margin-left: 30px;
        margin-bottom: 100px;
        flex-direction: column;
        align-items: flex-start;
    }

    .homeOurServices-topInfoContainer h2 {
        font-size: 2.25rem;
        line-height: 2rem;
    }

    .homeOurServices-topInfoContainer p {
        font-size: 1.125rem;
        line-height: 1.406rem;
        width: 50%;
    }
}

@media (width < 800px) {
    .homeOurServices-topInfoContainer {
        flex-direction: column;
        margin-left: 18px;
        margin-right: 18px;
    }
    .homeOurServices-topInfoContainer h2 {
        font-size: 2rem;
        line-height: 1.688rem;
        width: 100%;
    }

    .homeOurServices-topInfoContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
        width: 100%;
    }
}