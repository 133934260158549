.stripeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 330px;
    gap: 1px;
    overflow: hidden;
}

.stripe-diamond {
    width: 10px;
    height: 10px;
    background-color: var(--text-color);
    margin: 0;
    padding: 0;
    transition: 0.5s ease-in-out;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);}

.stripe-diamond.stripe-animate {
    background-color: #fff;
}