.aboutFrontPageContainer {
    width: 100%;
    height: 100vh;
    min-height: 1000px;
    position: relative;
}

.aboutFrontPage-title {
    position: absolute;
    font-size: 6.25rem;
    font-weight: 700;
    line-height: 6.146rem;
    color: var(--white-color);
    margin: 0;
    padding: 0;
}

.aboutFrontPage-title1 {
    left: 10.93%;
    top: 30.61%;
}

.aboutFrontPage-title2 {
    right: 23.49%;
    top: 55.95%;
}

.aboutFrontPage-title2::after {
    content: "";
    width: 100%;
    height: 4px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    bottom: 1.9rem;
}

.aboutFrontPage-quoteContainer {
    position: absolute;
    right: 22.29%;
    top: 82.81%;
    width: 34.89%;
}

.aboutFrontPage-quote1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.875rem;
    margin: 0;
    padding: 0;
    color: var(--white-color);
}

.aboutFrontPage-quote2 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.563rem;
    margin: 0;
    padding: 0;
    color: var(--white-color);
    margin-top: 40px;
    float: right;
}

@media (799px < width < 1025px) {
    .aboutFrontPage-title1 {
        left: 80px;
        top: 26.98%;
    }

    .aboutFrontPage-title2 {
        right: 60px;
        top: 57.70%;
    }

    .aboutFrontPage-quoteContainer {
        right: 60px;
        top: 81.58%;
        width: 50%;
    }

    .aboutFrontPage-title {
        font-size: 4.688rem;
        line-height: 4.609rem;
    }

    .aboutFrontPage-quote1 {
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: justify;
    }

    .aboutFrontPage-quote2 {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-top: 16px;
    }
}

@media (width < 800px) {
    .aboutFrontPage-title1 {
        left: 22px;
        top: 36.07%;
    }

    .aboutFrontPage-title2 {
        right: 22px;
        top: 48.88%;
    }
    .aboutFrontPage-quoteContainer {
        right: 18px;
        top: 73.44%;
        width: 60%;
    }
    .aboutFrontPage-title {
        font-size: 3.125rem;
        line-height: 3.073rem;
    }
    .aboutFrontPage-quote1 {
        font-size: 1rem;
        line-height: 1.25rem;
        text-align: justify;
    }
    .aboutFrontPage-quote2 {
        font-size: 0.875rem;
        line-height: 1.094rem;
        margin-top: 40px;
    }
    .aboutFrontPage-title2::after {
        bottom: 1rem;
    }
}