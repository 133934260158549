.navbarContainer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 46px;
}

.navbarContainer ul li {
    padding: 10px 20px;
}

.navbarContainer ul li a {
    color: inherit;
}

.navbarContainer .navLink-special {
    border: 1px solid var(--white-color);
    padding: 10px 30px;
    position: relative;
    overflow: hidden;
    transition: color 0.2s ease-in-out;
}


.navbarContainer .navLink-special:hover {
    cursor: pointer;
}

.navbarContainer .navLink-special::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    z-index: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.navbarContainer .navLink-special:hover::before {
    transform: translateY(0);
}

.navbarContainer .navLink-special:hover {
    color: var(--white-color);
}

.navbarContainer .navLink-special a {
    position: relative;
    z-index: 1;
}

.navLink-line-animation a {
    position: relative;
}

.navLink-line-animation a::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--white-color);
    transition: 0.2s ease-in-out;
    position:absolute;
    bottom: -2px;
    left: 0;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s ease-in-out;
}

.navLink-specialColor:hover {
    color: rgb(22, 22, 23)!important;
}

.navLink-line-animation a:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}

@media (width < 1025px) {
    .navbarContainer ul {
        flex-direction: column;
        gap: 10px;
    }
    .navbarContainer ul li {
        padding: 10px;
    }
}