.ubicationContainer {
    margin-top: 100px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 130px;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.ubication-textContainer {
    width: 50%;
    padding-right: 230px;
    box-sizing: border-box;
}

.ubication-mapContainer {
    width: 50%;
    box-sizing: border-box;
}

.ubication-textContainer h2 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
}

.ubication-textContainer h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 103px;
}

.ubication-textContainer h4 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.563rem;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px;
}

.ubication-textContainer h4 img {
    width: 24px;
}

.ubication-textContainer h4:first-of-type {
    margin-top: 50px;
}

.ubication-textContainer p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.406rem;
    margin: 0;
}

.ubication-mapContainer iframe {
    height: 100%;
    width: 100%;
    border: none;
}

@media (799px < width < 1025px) {
    .ubicationContainer {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 60px;
        justify-content: flex-end;
    }

    .ubication-textContainer {
        padding: 0;
    }

    .ubication-textContainer h2 {
        line-height: 2.172rem;
    }

    .ubication-textContainer h3 {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-bottom: 60px;
    }

    .ubication-textContainer h4 {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-top: 17px;
        margin-bottom: 12px;
    }

    .ubication-textContainer p {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-top: 5px;
    }

}

@media (width < 800px) {
    .ubicationContainer {
        margin-left: 18px;
        margin-right: 18px;
        margin-top: 60px;
        margin-bottom: 100px;
        flex-direction: column;
        justify-content: center;
    }

    .ubication-textContainer {
        width: 100%;
        padding-right: 0;
    }

    .ubication-textContainer h2 {
        font-size: 1.75rem;
        line-height: 2.188rem;
    }

    .ubication-textContainer h3 {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-bottom: 40px;
    }

    .ubication-mapContainer {
        width: 100%;
        aspect-ratio: 354 / 248;
    }

    .ubication-textContainer h4 {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-bottom: 14px;
    }

    .ubication-textContainer h4:first-of-type {
        margin-top: 44px;
    }

    .ubication-textContainer h4:nth-last-of-type(2) {
        margin-top: 26px;
    }

    .ubication-textContainer h4:nth-last-of-type(3) {
        margin-top: 18px;
    }

    .ubication-textContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-top: 5px;
    }

}