.homeFrontPageContainer {
    height: 100vh;
    min-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.homeFrontPageContainer::-webkit-scrollbar {
    display: none;
}

.homeFrontPage-wrapper {
    height: 300vh;
    width: 100%;
}

.homeFrontPage-wrapperInfo {
    height: 100vh;
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeFrontPage-videoTextContainer {
    width: 480px;
    max-height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.homeFrontPage-videoContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 480px;
    height: 480px;
    max-height: 480px;
    transition: 1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 2;
}

.homeFrontPage-videoContainer-filter {
    filter: invert();
}

.homeFrontPage-videoContainer video {
    width: 100vw !important;
    height: 100vh !important;
    position: static !important;
}

.homeFrontPage-videoContainer-startPosition {
    max-height: 0;
    transform-origin: center;
}

.homeFrontPage-videoContainer-setBigger {
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    max-height: 100vh;
    transform: none;
}

.homeFrontPage-titleContainer {
    width: 0px;
    transition: 1s ease-in-out;
    margin-left: 520px;
    margin-right: 520px;
    position: relative;
}

.homeFrontPage-titleContainer h2 {
    font-size: 160px;
    font-weight: 400;
    line-height: 250px;
    margin: 0;
    color: var(--red-color);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}

.homeFrontPage-titleContainer h2::after {
    content: "";
    background-color: var(--red-color);
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 5.5rem;
    left: 0;
}

.homeFrontPage-titleContainer1 {
    transform: rotateZ(-90deg);
}

.homeFrontPage-titleContainer2 {
    transform: rotateZ(90deg);
}

.homeFrontPage-titleContainer1-startPosition {
    transform: rotateZ(-90deg) translateX(150%);
}

.homeFrontPage-titleContainer2-startPosition {
    transform: rotateZ(90deg) translateX(150%);
}

.homeFrontPage-listContainer {
    position: absolute;
    width: 100%;
    bottom: -366px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    opacity: 1;
    transition: 1s ease-in-out;
}

.homeFrontPage-listContainer-startPosition {
    opacity: 0;
}

.homeFrontPage-listContainer li {
    font-size: 32px;
    font-style: italic;
    font-weight: 400;
    line-height: 40px;
}

@media (799px < width < 1137px) or (height < 810px) {
    .homeFrontPage-videoTextContainer {
        width: 430px;
    }

    .homeFrontPage-videoContainer {
        width: 430px;
        height: 430px;
    }

    .homeFrontPage-titleContainer {
        margin-left: 350px;
        margin-right: 350px;
    }

    .homeFrontPage-titleContainer h2 {
        font-size: 140px;
        line-height: 187px;
    }

    .homeFrontPage-listContainer {
        bottom: -300px;
    }

    .homeFrontPage-listContainer li {
        font-size: 24px;
        line-height: 30px;
    }
}

@media (width < 800px) or (height < 740px) {
    .homeFrontPage-videoTextContainer {
        width: 211px;
    }

    .homeFrontPage-videoContainer {
        width: 211px;
        height: 211px;
    }

    .homeFrontPage-titleContainer {
        margin-left: 160px;
        margin-right: 160px;
    }

    .homeFrontPage-titleContainer h2 {
        font-size: 80px;
        line-height: 100px;
    }

    .homeFrontPage-listContainer {
        bottom: -200px;
    }

    .homeFrontPage-listContainer li {
        font-size: 14px;
        line-height: 17.5px;
    }

    .homeFrontPage-listContainer li:nth-of-type(2),
    .homeFrontPage-listContainer li:nth-of-type(4) {
        transform: scale(0.4);
    }
}