:root {
  --text-color: #0D3579;
  --light-gray: #ECECEC;
  --white-color: #FFFFFF;
  --noselected-color: #D9D9D9;
  --light-gray2: #9B9B9B;
  --red-color: #F2332C;
}

body {
  font-family: "Familjen Grotesk", sans-serif;
  color: var(--text-color);
}
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fadeIn-animation {
  animation: 1s fadeIn;
}

.opacity-animation {
  animation: 2s opacity;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}