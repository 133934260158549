.services-infoAndWhyContainer {
    position: relative;
    height: 500vh;
}

.services-infoAndWhyWrapper {
    position: sticky;
    top: 0;
    transform: translateY(0%);
    transition: 0.7s ease-in-out;
}

.services-infoAndWhyWrapper2 {
    position: sticky;
    top: 0;
}

.services-infoAndWhyWrapper-z1 {
    z-index: 4;
}

.services-infoAndWhyWrapper-z2 {
    z-index: 3;
}

.services-info-translate {
    transform: translateY(-150%);
}

.servicesPage-section-title {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    width: 40%;
    margin-left: 90px;
    margin-top: 120px;
    margin-bottom: 119px;
}

@media (799px < width < 1025px) {
    .servicesPage-section-title {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.406rem;
        width: 24.813rem;
        margin-left: 30px;
        margin-top: 60px;
        margin-bottom: 121px;
    }
}

@media (width < 800px) {
    .servicesPage-section-title {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: left;
        margin-left: 8px;
        margin-right: 36px;
        margin-top: 100px;
        margin-bottom: 130px;
    }

}