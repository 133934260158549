.testimonyContainer {
    position: relative;
    width: 100%;
    height: 90vh;
    min-height: 600px;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.testimony-infoContainer {
    width: 30%;
    color: var(--white-color);
}

.testimony-starsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.testimony-starsContainer img {
    width: 20px;
}

.testimony-text-quote {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.563rem;
    text-align: center;
    margin: 0;
    margin-top: 16px;
}

.testimony-text-quote2 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 42px;
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    z-index: -1;
}

@media (799px < width < 1025px) {
    .testimonyContainer {
        margin-top: 100px;
        height: 807px;
    }

    .testimony-infoContainer {
        width: 42.44%;
    }

    .testimony-text-quote {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-top: 20px;
    }
    .testimony-text-quote2 {
        margin-bottom: 48.5px;
    }
}

@media (width < 800px) {
    .testimonyContainer {
        margin-top: 100px;
        height: 538px;
        padding-left: 18px;
        padding-right: 18px;
        box-sizing: border-box;
    }
    .testimony-infoContainer {
        width: 100%;
    }
    .testimony-text-quote {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-top: 20px;
    }
    .testimony-text-quote2 {
        font-size: 0.875rem;
        line-height: 1.094rem;
        margin-bottom: 9px;
    }
}