.contactInfoContainer {
    margin-top: 126px;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 130px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 150px;
}

.contactInfo-data ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.contactInfo-data li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
}

.contactInfo-data img {
    width: 24px;
}

.contactInfo-data p {
    padding: 0;
    margin: 0;
}

.contactInfo-days {
    margin-top: 135px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
}

.contact-infoContent {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.563rem;
}

@media (799px < width < 1025px) {
    .contactInfoContainer {
        margin-top: 120px;
        margin-left: 142px;
        margin-right: 142px;
        margin-bottom: 95px;
        flex-direction: column-reverse;
        gap: 95px;
    }
    .contact-infoContent {
        font-size: 1.125rem;
        line-height: 1.406rem;
    }
    .contactInfo-days {
        margin-top: 60px;
        flex-direction: column;
        gap: 16px;
    }
    .contactInfo-days h3 {
        margin: 0;
        margin-bottom: 11px;
    }
    .contactInfo-days p {
        margin: 0;
    }
}

@media (width < 800px) {
    .contactInfoContainer {
        margin-top: 60px;
        margin-left: 18px;
        margin-right: 18px;
        margin-bottom: 60px;
        flex-direction: column-reverse;
        gap: 60px;
    }
    .contact-infoContent {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .contactInfo-days {
        margin-top: 64px;
        flex-direction: column;
        gap: 16px;
    }
    .contactInfo-days h3 {
        margin: 0;
        margin-bottom: 11px;
    }
    .contactInfo-days p {
        margin: 0;
    }
}