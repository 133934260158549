.aboutDescriptionContainer {
    margin-top: 175px;
    margin-left: 80px;
    margin-right: 80px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 50px;
}

.aboutDescription-textContainer {
    width: 50%;
}

.aboutDescription-textContainer h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: -0.01em;
    margin: 0;
    padding: 0;
}

.aboutDescription-textContainer h3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

.aboutDescription-textContainer p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.7rem;
    margin: 0;
    padding: 0;
    margin-top: 40px;
    width: 80%;
}

.aboutDescription-imgContainer {
    width: 50%;
    height: auto;
    position: relative;
}

.aboutDescription-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.aboutDescription-imgContainer-whiteBox {
    width: 30%;
    height: 55%;
    position: absolute;
    background-color: #fff;
}

.aboutDescription-imgContainer-whiteBox1 {
    left: -1px;
    bottom: -1px;
}

.aboutDescription-imgContainer-whiteBox2 {
    right: -1px;
    top: -1px;
}

@media (799px < width < 1025px) {
    .aboutDescriptionContainer {
        margin-top: 93px;
        margin-left: 30px;
        margin-right: 30px;
        gap: 20px;
    }

    .aboutDescription-imgContainer-whiteBox {
        display: none;
    }

    .aboutDescription-imgContainer {
        width: 45%;
    }

    .aboutDescription-imgContainer img {
        position: static;
        height: auto;
        aspect-ratio: 377 / 400;
    }

    .aboutDescription-textContainer h2 {
        font-size: 2.25rem;
        line-height: 2rem;
        width: 70%;
    }
    .aboutDescription-textContainer h3 {
        line-height: 2.172rem;
        margin-top: 24px;
        width: 70%;
    }
    .aboutDescription-textContainer p {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-top: 16px;
        width: 80%;
    }
}

@media (width < 800px) {
    .aboutDescriptionContainer {
        margin-top: 60px;
        margin-left: 18px;
        margin-right: 18px;
        gap: 40px;
        flex-direction: column;
    }
    .aboutDescription-imgContainer-whiteBox {
        display: none;
    }
    .aboutDescription-imgContainer {
        width: 100%;
    }

    .aboutDescription-imgContainer img {
        position: static;
        height: auto;
        aspect-ratio: 1 / 1;
    }
    .aboutDescription-textContainer {
        width: 100%;
    }
    .aboutDescription-textContainer h2 {
        font-size: 2rem;
        line-height: 1.688rem;
        width: 80%;
    }
    .aboutDescription-textContainer h3 {
        font-size: 1.75rem;
        line-height: 2.188rem;
        margin-top: 24px;
        width: 80%;
    }
    .aboutDescription-textContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-top: 16px;
        width: 100%;
    }
}