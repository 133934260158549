.homeOurClientesContainer {
    margin-top: 158px;
    margin-left: 80px;
    margin-right: 80px;
}

.homeOurClientesContainer h2 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
}

.homeOurClients-imgsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.homeOurClients-imgContainer {
    width: 200px;
    height: 200px;
    transition: 1s ease-in-out;
}

.homeOurClients-imgContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.homeOurClients-imgContainer-animation:nth-last-of-type(2n) {
    transform: translateY(100px);
}

.homeOurClients-imgContainer-animation:nth-last-of-type(2n-1) {
    transform: translateY(-100px);
}

@media (width < 1025px) {
    .homeOurClientesContainer {
        margin-top: 59px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .homeOurClientesContainer h2 {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.406rem;
    }

    .homeOurClients-imgsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
    }
    .homeOurClients-imgsContainer2 {
        margin-left: 150px;
        margin-right: 150px;
    }
    .homeOurClients-imgContainer {
        width: 150px;
        height: 150px;
    }
}

@media (width < 800px) {
    .homeOurClientesContainer {
        margin-left: 17px;
        margin-right: 17px;
    }
    .homeOurClientesContainer h2 {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .homeOurClients-imgsContainer2 {
        margin-left: 100px;
        margin-right: 100px;
    }
    .homeOurClients-imgContainer {
        width: 100px;
        height: 100px;
    }
}