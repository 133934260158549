.formContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 780px;
}

.form-labelInputContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.formContainer label {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.563rem;
    text-align: left;
}

.formContainer input,
.formContainer textarea {
    width: 100%;
    border: 1px solid var(--text-color);
    margin-bottom: 16px;
    margin-top: 8px;
    box-sizing: border-box;
}

.formContainer input {
    height: 2.938rem;
}

.formContainer textarea {
    height: 12rem;
}

.form-firtsLastName {
    width: 100%;
    display: flex;
    gap: 24px;
}

.form-buttonContainer {
    width: 100%;
    text-align: end;
    margin-top: 44px;
}

@media (799px < width < 1025px) {
    .form-firtsLastName {
        flex-direction: column;
        gap: 0;
    }
    .formContainer label {
        font-size:1.125rem;
        line-height: 1.406rem;
    }
    .form-buttonContainer {
        width: 100%;
        margin-top: 0;
    }
    .formContainer textarea {
        margin-bottom: 40px;
    }
}

@media (width < 800px) {
    .formContainer {
        width: 100%;
    }
    .form-firtsLastName {
        flex-direction: column;
        gap: 0;
    }
    .formContainer label {
        font-size:1rem;
        line-height: 1.25rem;
    }
}