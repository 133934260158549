.homeAboutUsContainer {
    width: 100%;
    height: 900vh;
    min-height: 700px;
    background-color: var(--light-gray);
    position: relative;
}

.homeAboutUs-itemsContainer {
    width: 100%;
    height: 100vh;
    min-height: 700px;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
}

.homeAboutUs-itemContainer {
    width: 440px;
    height: 0;
}


.homeAboutUs-item-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
    margin-bottom: 20px;
    transform: translateY(100%);
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.homeAboutUs-item-desc {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.875rem;
    text-align: left;
    margin: 0;
    opacity: 0;
    transform: translateY(100%);
    transition: 0.4s ease-in-out;
}

.homeAboutUs-item-elementFadeIn {
    transform: translateY(0);
    opacity: 1;
}

.homeAboutUs-items-ball {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    position: absolute;
    top: 38%;
    left: -100px;
    transform: translateX(-50%);
    transition: 0.4s ease-in-out;
    overflow: hidden;
}

.homeAboutUs-items-videoWrapper {
    width: calc(100vw - 160px);
    height: calc(100vh - 200px);
    position: absolute;
    top: 0;
    left: 0;
}

.homeAboutUs-items-videoWrapper video {
    transition: 0.4s ease-in-out;
}

.homeAboutUs-items-video-textContainer {
    position: absolute;
    color: var(--white-color);
    bottom: 73px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 109px;
    margin-left: 109px;
    opacity: 0;
    transition: 0.4s ease-in-out;
    transition-delay: 1s;
}

.homeAboutUs-items-video-textContainer-fadeIn {
    opacity: 1;
}

.homeAboutUs-items-video-textContainer-info {
    width: 75%;
}

.homeAboutUs-items-video-textContainer-info h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4rem;
    letter-spacing: -1px;
    width: 100%;
    margin: 0;
    margin-bottom: 24px;
}

.homeAboutUs-items-video-textContainer-info p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
    letter-spacing: -1px;
    width: 60%;
    margin: 0;
}

.homeAutoUs-video-textContainer-link {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.7rem;
    border-bottom: 1px solid var(--white-color);
    width: fit-content;
    position: relative;
    padding-bottom: 4px;
}

.homeAboutUs-items-ball-firstPosition {
    left: calc(33.3333% - 320px);
}

.homeAboutUs-items-ball-secondPosition {
    left: calc(66.6666% - 345px);
}

.homeAboutUs-items-ball-thirdPosition {
    left: calc(99.9999% - 362px);
}

.homeAboutUs-items-ball-expand {
    width: calc(100% - 160px);
    height: calc(100% - 200px);
    box-sizing: border-box;
    top: 100px;
    left: 80px;
    transform: none;
    border-radius: 0;
    z-index: 2;
}

.homeAboutUsMarkerContainer {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding-top: 50vh;
}

.homeAboutUsMarker {
    width: 10px;
    height: 10px;
    margin-bottom: 100vh;
    background-color: red;
}

.homeAboutUs-items-video-invert video {
    filter: invert();
}

@media (width < 1025px) {
    .homeAboutUsContainer {
        background-color: var(--white-color);
        height: fit-content;
        margin-top: 100px;
    }

    .homeAboutUsContainer ul {
        list-style: none;
        padding: 0;
        margin-left: 30px;
        width: 50%;
    }

    .homeAboutUsContainer h3 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.172rem;
        margin: 0;
        margin-bottom: 16px;
    }

    .homeAboutUsContainer p {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.406rem;
        margin: 0;
        margin-bottom: 30px;
    }

    .aboutUsVideoContainer {
        width: 100%;
        height: 100vh;
        position: relative;
        color: var(--white-color);
        z-index: 2;
        margin-top: 331px;
    }

    .aboutUsVideoContainer h2 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.01em;
        margin: 0;
        margin-bottom: 16px;
    }

    .aboutUsVideoContainer p {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.406rem;
        margin: 0;
    }

    .aboutUsVideoContainer-textContainer {
        position: absolute;
        left: 30px;
        bottom: 330px;
        width: 50%;
    }

    .aboutUsVideoContainer-linkContainer {
        position: absolute;
        right: 38px;
        bottom: 100px;
        border-bottom: 1px solid var(--white-color);
    }

    .aboutUsVideoContainer-linkContainer a {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.7rem;
    }

}

@media (width < 800px) {
    .homeAboutUsContainer {
        margin-top: 116px;
    }

    .homeAboutUsContainer ul {
        margin-left: 0;
        width: 100%;
        padding-left: 18px;
        padding-right: 18px;
        box-sizing: border-box;
    }

    .homeAboutUsContainer h3 {
        font-size: 1.75rem;
        line-height: 1.9rem;
    }

    .homeAboutUsContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .aboutUsVideoContainer {
        margin-top: 124px;
    }

    .aboutUsVideoContainer h2 {
        line-height: 1.688rem;
    }

    .aboutUsVideoContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .aboutUsVideoContainer-linkContainer {
        right: 18px;
        bottom: 60px;
    }

    .aboutUsVideoContainer-textContainer {
        left: 0;
        bottom: 158px;
        padding-left: 18px;
        padding-right: 18px;
        width: 100%;
        box-sizing: border-box;
    }

}