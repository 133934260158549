.servicesInfoContainer {
    min-height: 100vh;
    margin-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    display: grid;
    grid-template-columns: 1fr 1px 2fr;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--white-color);
}

.servicesInfoContainer h2 {
    font-size: 100px;
    font-weight: 700;
    line-height: 100px;
    box-sizing: border-box;
    margin: 0;
}

.servicesInfo-verticalLine {
    width: 1px;
    background-color: var(--light-gray2);
    box-sizing: border-box;
    height: 100%;
}


.servicesInfo-itemsContainer {
    box-sizing: border-box;
    margin-left: 54px;
}

.servicesInfo-item-textMargin {
    margin-bottom: 200px !important;
}

.servicesInfo-itemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 90px;
}

.servicesInfo-item-textContainer {
    width: 60%;
}

.servicesInfo-item-textContainer h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    margin: 0;
    margin-top: 90px;
}

.servicesInfo-item-textContainer h3:first-of-type {
    margin-top: 100px;
}

.servicesInfo-item-textContainer p {
    font-family: Familjen Grotesk;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    margin: 0;
    margin-top: 20px;
}

.servicesInfo-item-list {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: var(--light-gray2);
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 114px;
}

.servicesInfo-diamondLogoContainer {
    position: absolute;
    right: 0;
    bottom: 0;
}

@media (1249px < width < 1500px) {
    .servicesInfoContainer h2 {
        font-size: 80px;
        line-height: 75px;
    }
}

@media (1024 < width < 1250px) {
    .servicesInfoContainer h2 {
        font-size: 60px;
        line-height: 55px;
    }
}

@media (799px < width < 1025px) {
    .servicesInfo-itemsContainer {
        margin-left: 110px;
        margin-right: 110px;
    }

    .servicesInfoContainer {
        padding-left: 0;
        padding-right: 0;
        height: fit-content;
    }

    .servicesInfoContainer h2 {
        font-size: 36px;
        line-height: 32px;
        margin-left: 29px;
        margin-right: 29px;
    }

    .servicesInfo-item-textContainer {
        width: 100%;
    }

    .servicesInfo-item-textContainer h3 {
        line-height: 34.752px;
        margin-top: 76px;
    }

    .servicesInfo-item-textContainer p {
        font-size: 18px;
        line-height: 22.5px;
        margin: 0;
        margin-top: 16px;
    }

    .servicesInfo-item-list {
        display: none;
    }

    .servicesInfo-item-textMargin {
        margin-bottom: 76px !important;
    }
}

@media (width < 800px) {

    .servicesInfo-verticalLine,
    .servicesInfo-item-list {
        display: none;
    }

    .servicesInfoContainer {
        display: flex;
        justify-content: center;
        padding: 0;
        margin-left: 22px;
        margin-right: 15px;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        margin-top: 0;
        border-left: 1px solid var(--light-gray2);
    }

    .servicesInfoContainer h2 {
        font-size: 32px;
        line-height: 27px;
        margin-left: 23px;
        margin-right: 155px;
        margin-bottom: 60px;
    }

    .servicesInfo-item-textContainer h3 {
        font-size: 28px;
        line-height: 35px;
        margin-top: 0;
    }
    .servicesInfo-item-textContainer p {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        margin-top: 16px;
        margin-bottom: 24px;
        width: 75%;
    }
    .servicesInfo-itemsContainer {
        margin-left: 22px;
    }
    .servicesInfo-item-textContainer {
        width: 100%;
    }

    .servicesInfo-item-textMargin {
        margin-bottom: 100px !important;
    }
}