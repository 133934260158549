.whyColorGroupContainer {
    margin-top: 82px;
    min-height: 100vh;
    position: relative;
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.whyColorGroupContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.whyColorGroupContainer h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.01em;
    width: 500px;
    margin: 0;
    margin-bottom: 50px;
}

.whyColorGroupContainer h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
    margin-top: 36px;
}

.whyColorGroupContainer p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    margin-top: 16px;
}

.whyColorGroupContainer ol {
    width: 500px;
    list-style: none;
    padding: 0;
}

.whyColorGroupContainer ol li {
    position: relative;
    counter-increment: list-counter;
}

.whyColorGroupContainer ol li::before {
    content: "0" counter(list-counter);
    position: absolute;
    left: -105px;
    font-size: 20px;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: -0.01em;
}

.whyColorGroup-verticalLine {
    width: 1px;
    height: 80%;
    background-color: #fff;
    position: absolute;
    right: 120px;
}

@media (799px < width < 1025px) {
    .whyColorGroup-verticalLine {
        display: none;
    }

    .whyColorGroupContainer {
        justify-content: flex-start;
    }

    .whyColor-contentContainer {
        margin-left: 35.61%;
        border-left: 1px solid var(--light-gray2);
        padding-left: 100px;
        width: 333px;
    }

    .whyColorGroupContainer h2 {
        font-size: 2.25rem;
        line-height: 2rem;
        margin-bottom: 60px;
        width: 100%;
    }

    .whyColorGroupContainer ol {
        width: 100%;
    }

    .whyColorGroupContainer h3 {
        line-height: 2.172rem;
        margin-top: 0px;
        margin-bottom: 16px;
        width: 100%;
    }

    .whyColorGroupContainer p {
        font-size: 1.125rem;
        line-height: 1.406rem;
        margin-top: 0px;
        margin-bottom: 39px;
    }

    .whyColorGroupContainer ol li::before {
        left: -30px;
        font-size: 0.875rem;
        line-height: 2.5rem;
    }

}

@media (width < 800px) {
    .whyColorGroup-verticalLine {
        display: none;
    }
    .whyColor-contentContainer {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        box-sizing: border-box;
    }
    .whyColorGroupContainer h2 {
        font-size: 2rem;
        line-height: 1.688rem;
        margin-bottom: 69px;
        width: 100%;
        padding-left: 27px;
        box-sizing: border-box;
    }
    .whyColorGroupContainer ol {
        width: 100%;
        padding-left: 27px;
        box-sizing: border-box;
    }
    .whyColorGroupContainer h3 {
        font-size: 1.75rem;
        line-height: 2.188rem;
        margin-top: 0px;
        margin-bottom: 16px;
        width: 100%;
    }

    .whyColorGroupContainer p {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-top: 0px;
        margin-bottom: 33px;
    }
    .whyColorGroupContainer ol li::before {
        left: -25px;
        font-size: 0.875rem;
        line-height: 2.9rem;
    }
}